import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroSection from "../components/heroSection";
// import ModalPopUp from "../components/modalPopUp";
import MenuSection from "../components/menuSection";
// import CateringSection from "../components/cateringSection";
import LocationSection from "../components/locationSection";
import AboutSection from "../components/aboutSection";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    {/* <ModalPopUp /> */}
    <MenuSection />
    {/* <CateringSection /> */}
    <LocationSection />
    <AboutSection />
  </Layout>
);

export default IndexPage;
