import React from "react";
import SectionHeading from "./sectionHeading";
import textImg from "../images/menu/taco-bowls.jpg";

const AboutSection = () => {
  return (
    <section
      className="relative bg-ottos-grey"
      id="about"
      style={{ scrollMarginTop: "64px" }}
    >
      <div className="relative z-20">
        <SectionHeading label="about" />
      </div>
      <div className="absolute inset-0 z-10 bg-black opacity-75"></div>
      <img
        src={textImg}
        alt="Otto's Tacos Taco Bowl"
        className="absolute inset-0 z-0 object-cover h-full min-w-full"
      />
      <div className="relative z-10 flex flex-col justify-center py-20 mx-4 text-sm align-middle lg:py-32 font-montserrat md:text-base">
        <p className="w-full px-4 mx-auto mb-3 text-white md:w-10/12 lg:max-w-screen-md">
          Otto's Tacos launched in 2013 in the East Village of New York City.
          The team behind Otto's had one simple goal, to bring New Yorkers the
          best flavors found in taquerias sprinkled throughout Southern
          California. Since then, founder Otto Cedeno and chef Joseph LoNigro
          grew Otto's Tacos to 4 locations in beloved neighborhoods in
          Manhattan. Otto's has been featured and admired across many
          publications including NYMag, Eater, and Zagat. But the real measure
          of how great the tacos are, are from the thousands of customers and
          fans across social media.
        </p>
        <p className="w-full px-4 mx-auto mb-3 text-white md:w-10/12 lg:max-w-screen-md">
          In 2020, due to the growing global pandemic, Otto's Tacos sadly had to
          close its physical locations throughout the city. We cannot thank our
          staff, community and customers enough for supporting our small taco
          empire through an amazing seven year run.
        </p>
        <p className="w-full px-4 mx-auto mb-3 text-white md:w-10/12 lg:max-w-screen-md">
          But wait, there's also some good news. We have officially re-opened
          Otto's Tacos as a delivery only concept through an online-only
          delivery partnership with the fine folks at Mighty Quinn's. Our goal
          is to promptly re-open for delivery in as many areas of Manhattan as
          possible. For the latest news on this new initiative, please follow us
          on Instagram. We hope to bring our world class tacos to a delivery
          zone near you soon...
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
