const Locations = [
  {
    name: "Upper East Side, NYC",
    isOpen: true,
    description:
      "Orders are serviced from Mighty Quinn’s kitchen on 2nd ave and 78th st.  Delivery radius varies based on delivery platform.",
    directActive: true,
    directUrl:
      "https://order.online/store/otto's-tacos-new-york-1266506/?hideModal=true&pickup=true",
    platforms: [
      {
        active: true,
        name: "Caviar",
        url:
          "https://www.trycaviar.com/store/otto-s-tacos-new-york-1266506/en-US",
      },
      {
        active: true,
        name: "Door Dash",
        url:
          "https://www.doordash.com/store/otto-s-tacos-new-york-1266506/1595127/en-US",
      },
      {
        active: true,
        name: "Grubhub",
        url:
          "https://www.grubhub.com/restaurant/ottos-tacos-1492-2nd-ave-new-york/2438363",
      },
      {
        active: true,
        name: "Uber Eats",
        url:
          "https://www.ubereats.com/new-york/food-delivery/ottos-tacos-ues/_L-9CyWHQCS4JX74e3ySfg",
      },
      {
        active: true,
        name: "Postmates",
        url: "https://postmates.com/merchant/ottos-tacos-new-york-57928",
      },
    ],
  },
  {
    name: "East Village, NYC",
    isOpen: false,
    description:
      "Orders are serviced from Mighty Quinn’s kitchen on 2nd ave and east 6th st.  Delivery radius varies based on delivery platform.",
    directActive: false,
    directUrl: "https://order.ottostacos.com/",
    platforms: [
      {
        active: true,
        name: "Caviar",
        url: "https://www.trycaviar.com/store/otto's-tacos-new-york-1833156/",
      },
      {
        active: true,
        name: "Door Dash",
        url: "https://www.doordash.com/store/otto's-tacos-new-york-1833156",
      },
      {
        active: true,
        name: "Grubhub",
        url:
          "https://www.grubhub.com/restaurant/ottos-tacos-2nd-ave-103-2nd-ave-new-york/2862333",
      },
      {
        active: true,
        name: "Uber Eats",
        url:
          "https://www.ubereats.com/store/ottos-tacos-east-village/3FfCUbjwSUOmo33ZOTEiSw?diningMode=DELIVERY",
      },
      {
        active: true,
        name: "Postmates",
        url:
          "https://postmates.com/store/ottos-tacos-east-village/3FfCUbjwSUOmo33ZOTEiSw?diningMode=DELIVERY",
      },
    ],
  },
  {
    name: "Hoboken, NJ",
    isOpen: false,
    description:
      "Orders are serviced from Mighty Quinn’s kitchen on Washington Street.  Delivery radius varies based on delivery platform.",
    directActive: true,
    directUrl:
      "https://order.online/store/otto's-tacos-hoboken-23871518/?hideModal=true&pickup=true",
    platforms: [
      {
        active: false,
        name: "Caviar",
        url: "",
      },
      {
        active: true,
        name: "Door Dash",
        url:
          "https://www.doordash.com/store/otto's-tacos-hoboken-23871518/?event_type=autocomplete&pickup=false",
      },
      {
        active: true,
        name: "Grubhub",
        url:
          "https://www.grubhub.com/restaurant/ottos-tacos-322-washington-st-hoboken/3349987",
      },
      {
        active: true,
        name: "Uber Eats",
        url:
          "https://www.ubereats.com/store/ottos-tacos/OkpbpaNGU76zy1xQ09KN2w?diningMode=DELIVERY",
      },
      {
        active: true,
        name: "Postmates",
        url:
          "https://postmates.com/store/ottos-tacos/OkpbpaNGU76zy1xQ09KN2w?diningMode=DELIVERY",
      },
    ],
  },
];

export default Locations;
