import React from "react";

const SectionSubHeading = ({ label }) => {
  return (
    <h2 className="my-4 text-2xl font-black tracking-wide text-center uppercase font-lato text-primary-orange">
      {label}
    </h2>
  );
};

export default SectionSubHeading;
