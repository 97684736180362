import React from "react";
import SectionHeading from "./sectionHeading";
import SectionSubHeading from "./sectionSubHeading";
import MenuCardsContainer from "./menuCardsContainer";

import Menu from "../data/menuItems";

const MenuSection = () => {
  return (
    <section
      className="pb-4 bg-ottos-grey"
      id="menu"
      style={{ scrollMarginTop: "64px" }}
    >
      <SectionHeading label="menu" />

      <SectionSubHeading label="mains" />
      <div className="mx-4">
        <p className="mb-4 text-center text-ottos-dark-grey font-montserrat">
          Fill any main dish with the following options
        </p>
        <p className="font-black tracking-wide text-center uppercase font-lato text-ottos-dark-grey">
          chicken, carnitas, carne asada, or seasonal veggies
        </p>
      </div>
      <MenuCardsContainer menu={Menu.mains} />

      <SectionSubHeading label="sides" />
      <MenuCardsContainer menu={Menu.sides} />
    </section>
  );
};

export default MenuSection;
