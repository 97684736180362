import React from "react";

const MenuCard = ({ menuItem }) => {
  return (
    <div className="flex-1 max-w-md overflow-hidden shadow-xl rounded-2xl text-ottos-dark-grey">
      <img
        src={menuItem.image}
        alt={`${menuItem.name} - ${menuItem.description}`}
        className="object-cover w-full h-48 min-w-full lg:h-56"
      />
      <div className="p-4 h-36">
        <h3 className="pb-2 font-black tracking-wide uppercase font-lato">
          {menuItem.name}
        </h3>
        <p className="text-sm font-montserrat">{menuItem.description}</p>
      </div>
    </div>
  );
};

export default MenuCard;
