import React from "react";
import mainLogo from "../images/ottos-tacos-logo-large.svg";
import { PrimaryButton } from "./buttons";
import { motion } from "framer-motion";
import backgroundImg from "../images/menu/street-tacos.jpg";

const HeroSection = () => {
  return (
    <section className="relative h-screen" id="home">
      <div className="absolute inset-0 z-10 bg-black opacity-25"></div>
      <img
        src={backgroundImg}
        alt="Otto's Tacos famous carnitas taco"
        className="absolute inset-0 z-0 object-cover h-full min-w-full"
      />

      <motion.div
        className="absolute inset-0 z-10 flex flex-col items-center justify-center h-full"
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ type: "spring", stiffness: 80, damping: 14 }}
      >
        <img
          src={mainLogo}
          alt="Otto's Tacos Logo"
          className="w-3/4 max-w-sm"
        />
        <h1 className="pt-5 pb-16 text-2xl font-black tracking-wide text-center text-white uppercase font-lato">
          So-Cal Inspired Taqueria
        </h1>
        <PrimaryButton btnLabel="order now" url="/#locations" />
      </motion.div>
    </section>
  );
};

export default HeroSection;
