import React from "react";
import MenuCard from "./menuCard";

const renderMenuCards = menuArray => {
  return menuArray.map(menuItem => {
    return <MenuCard key={menuItem.name} menuItem={menuItem} />;
  });
};

const MenuCardsContainer = ({ menu }) => {
  return (
    <div
      id="main-menu-container"
      className="flex flex-col items-center justify-center mx-4 mt-10 mb-10 space-y-8 lg:flex-row lg:space-x-6 lg:space-y-0"
    >
      {renderMenuCards(menu)}
    </div>
  );
};

export default MenuCardsContainer;
