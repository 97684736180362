import React from "react";

const SectionHeading = ({ label }) => {
  return (
    <h1 className="flex flex-col justify-center h-16 text-2xl font-black tracking-wide text-center text-white uppercase font-lato bg-primary-orange">
      {label}
    </h1>
  );
};

export default SectionHeading;
