import React from "react";
import { CardPrimaryButton, CardSecondaryButton } from "./buttons";

const renderThirdPartyButtons = platforms => {
  return platforms.map(platform => {
    if (platform.active) {
      return (
        <div
          className="w-full my-3 md:w-3/4 lg:w-2/5 lg:my-4 lg:mx-3"
          key={platform.name}
        >
          <CardSecondaryButton btnLabel={platform.name} url={platform.url} />
        </div>
      );
    } else {
      return null;
    }
  });
};

const renderDirectOrdering = location => {
  if (location.directActive) {
    return (
      <div className="w-full mx-auto mt-8 mb-6 md:w-3/4 lg:w-2/5">
        <CardPrimaryButton btnLabel="ORDER DIRECT" url={location.directUrl} />
        <div className="mt-4">
          <p className="text-xs text-center text-ottos-dark-grey font-montserrat">
            or order from the following third parties
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const LocationCard = ({ location }) => {
  return (
    <div className="max-w-3xl p-4 mx-auto mb-12 border border-gray-400 shadow-md rounded-2xl lg:px-6">
      <h3 className="pb-2 text-xl font-black tracking-wide uppercase font-lato text-primary-orange">
        {location.name}
      </h3>
      <p className="text-sm md:pr-8 text-ottos-dark-grey md:mb-4 font-montserrat">
        {location.description}
      </p>
      {renderDirectOrdering(location)}
      <div className="flex flex-col flex-wrap content-center justify-center mt-4 align-middle lg:flex-row">
        {renderThirdPartyButtons(location.platforms)}
      </div>
    </div>
  );
};

export default LocationCard;
