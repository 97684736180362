import React from "react";
import SectionHeading from "./sectionHeading";
import LocationCard from "./locationCard";

import Locations from "../data/locations";

const renderLocationCards = () => {
  return Locations.map(location => {
    if (location.isOpen) {
      return <LocationCard key={location.name} location={location} />;
    } else {
      return null;
    }
  });
};

const LocationSection = () => {
  return (
    <section
      className="pb-16 bg-ottos-grey"
      id="locations"
      style={{ scrollMarginTop: "64px" }}
    >
      <SectionHeading label="locations" />
      <div className="mx-4">
        <p className="my-4 text-center text-ottos-dark-grey sm:my-8 font-montserrat">
          We are currently offering{" "}
          <span className="font-bold">delivery & pickup</span> from our partner
          restaurant locations.
        </p>
      </div>
      <div id="locations-container" className="mx-4 mt-10">
        {renderLocationCards()}
      </div>
    </section>
  );
};

export default LocationSection;
