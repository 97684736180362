import streetTacosImg from "../images/menu/street-tacos.jpg";
import tacoBowlsImg from "../images/menu/taco-bowls.jpg";
import mexiCobbSaladsImg from "../images/menu/mexi-cobb-salads.jpg";
import chipsAndGuacImg from "../images/menu/chips-and-guac.jpg";
import chipsAndSalsaImg from "../images/menu/chips-and-salsa.jpg";
import riceAndBeansImg from "../images/menu/rice-and-beans.jpg";

const Menu = {
  mains: [
    {
      name: "Street Taco",
      description:
        "Otto’s Signature Corn Tortilla, Salsa, Cilantro, Onion and Your Choice of Filling.",
      image: streetTacosImg,
    },
    {
      name: "Taco Bowl",
      description:
        "Rice and Beans with Pico de Gallo, Red Salsa, Serrano Crema, Guac, Cilantro, and Your Choice of Filling.",
      image: tacoBowlsImg,
    },
    {
      name: "Mexi-Cobb Salad",
      description:
        "Bed of Romaine Lettuce, Black Beans, Corn, Pico de Gallo, Crumbled Cotija, Masa Crutons, Avocado Ranch, and Your Choice of Filling.",
      image: mexiCobbSaladsImg,
    },
  ],
  sides: [
    {
      name: "Chips and Guac",
      description:
        "Lightly salted tortilla chips with our daily made guacamole.",
      image: chipsAndGuacImg,
    },
    {
      name: "Chips and Salsa",
      description:
        "Lightly salted tortilla chips with your choice of mild green or spicy red salsa.",
      image: chipsAndSalsaImg,
    },
    {
      name: "Rice and Beans",
      description:
        "Hefty scoops of classic refried beans and Mexican yellow rice.",
      image: riceAndBeansImg,
    },
  ],
};

export default Menu;
